export const technology = [
  {
    type: 'ad',
    img: new URL('../assets/ads/tech_zemlerob_sponsor.gif', import.meta.url),
    imgMobile: new URL('../assets/ads/tech_zemlerob_sponsor.gif', import.meta.url),
    height: '115px',
    heightMobile: '115px',
    url: 'https://www.facebook.com/zemlerob.com.ua/',
    time: '00:00 - 00:00',
  },
  {
    disable: false,
    type: 'report',
    time: '14:20',
    desc: 'Гібридна технологія обробітку грунту',
    speakerName: 'Сергій Суровий',
    speakerDesc: 'фермер, агроном-консультант, блогер',
    speakerImg: new URL('../assets/speakers/suroviy.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/suroviy_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '14:40',
    desc: 'Покладіть калій глибше. Методи збільшення ефективності калійного живлення',
    speakerName: 'Володимир Мірненко',
    speakerDesc: 'Бренд-менеджер компанії PÖTTINGER',
    speakerImg: new URL('../assets/speakers/Mirnenko.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/Mirnenko_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '11:20',
    desc: 'Технологія вирощування цукрових буряків із усіма цифрами і підводними каменями',
    speakerName: 'Роман Шнуровський',
    speakerDesc: 'АПП «ЗОЛОТА НИВА»',
    speakerImg: new URL('../assets/speakers/shnurovsky.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/empty.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '11:00',
    desc: 'Економія, захист, живлення: МІКРОрганізми в дії!',
    speakerName: 'Олександр Ростоцький',
    speakerDesc: 'Головний агроном компанії БТУ-ЦЕНТР',
    speakerImg: new URL('../assets/speakers/rostockiy.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/rostockiy_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '10:00',
    desc: 'Технологія здешевлення сушіння зерна в умовах нестабільності енергоносіїв',
    speakerName: 'Іван Корженко',
    speakerDesc: 'власник, компанія Greco group',
    speakerImg: new URL('../assets/speakers/korshenko.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/korshenko_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'reportMulti',
    time: '11:40',
    desc: 'ПРИБУТКОВА МІКРОБІОЛОГІЯ В ДІЇ. РЕАЛЬНІ ЦИФРИ',
    speakers: [
      {
        speakerName: 'Олександр Карнаух',
        speakerDesc: 'продакт менеджер відділу добрив і мікродобрив ГК «Агро Експерт»',
        speakerImg: new URL('../assets/speakers/alex_karnaux.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/alex_karnaux_agroexpert.png?as=webp', import.meta.url),
      },
      {
        speakerName: 'Людвікас Бабаляускас',
        speakerDesc: 'Global sales director, NANDO (Литва)',
        speakerImg: new URL('../assets/speakers/ludbabalyauskas.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/ludbabalyauskas_logo.png?as=webp', import.meta.url),
      },
    ],
  },
  {
    type: 'report',
    time: '10:20',
    desc: ' Біотехнологія оптимізації азотного живлення',
    speakerName: 'Аврамчук Богдан',
    speakerDesc: 'Керівник відділу розвитку продуктів "LIFE BIOCHEM"',
    speakerImg: new URL('../assets/speakers/avramchuk.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/andrushenko_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '12:20',
    desc: 'Інноваційні рішення в живленні рослин',
    speakerName: 'Роман Сластьон',
    speakerDesc: 'Керівник ICL Growing Solutions в Україні',
    speakerImg: new URL('../assets/speakers/slastion.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/slastion_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '10:40',
    desc: 'Які технології допоможуть аграріям бути ефективнішими',
    speakerName: 'Денис Глушко',
    speakerDesc: 'Product Owner в Київстар',
    speakerImg: new URL('../assets/speakers/glushko.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/glushko_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '12:00',
    desc: 'Блокування кордонів та протестні настрої в Європі. Чи завдає українське зерно шкоди країнам ЄС?',
    speakerName: 'Денис Мараренко',
    speakerDesc: 'Керівник департаменту міжнародного трейдингу з агропродукції',
    speakerImg: new URL('../assets/speakers/mararenko.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/bovtachhuk_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '15:40',
    desc: '33 $/га – рахуємо витрати на запчастини та ремонти',
    speakerName: 'Богдан Кривіцький',
    speakerDesc: 'Технічний директор ІМК',
    speakerImg: new URL('../assets/speakers/krivickiy.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/krivickiy_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '14:00',
    desc: 'Ph ґрунту та його вплив на урожайність культур і що з цим можна зробити',
    speakerName: 'Юрій Лисак',
    speakerDesc: ' головний агроном ГК «Агро-Регіон»',
    speakerImg: new URL('../assets/speakers/lisak.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/lisak_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '15:00',
    desc: 'Теоритичні основи управління фосфорним живленням',
    speakerName: 'Сергій Галушка',
    speakerDesc: 'Західний хаб МХП, Технолог по живленню',
    speakerImg: new URL('../assets/speakers/galushka.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/galushka_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '16:00',
    desc: 'Питання - відповідь',
    speakerName: 'Дмитро Граца',
    speakerDesc: 'технічний керівник Агропросперіс Груп',
    speakerImg: new URL('../assets/speakers/graca.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/graca_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '16:20',
    desc: 'Тема уточнюється',
    speakerName: 'ТОП СПІКЕР',
    speakerDesc: '',
    speakerImg: new URL('../assets/speakers/toptop.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/toptop_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'break',
    time: '09:00',
    title: 'РЕЄСТРАЦІЯ',
  },
  {
    type: 'break',
    time: '09:40',
    title: 'Відкриття Re:farm 2024. Презентація навчального центру BAZA',
  },
  {
    type: 'break',
    time: '13:00',
    title: 'ОБІД',
  },
  {
    type: 'break',
    time: '17:30',
    title: 'ВЕЧІРНЯ ПРОГРАМА',
  },
];
