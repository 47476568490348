export const precisionAgriculture = [
  {
    type: 'ad',
    img: new URL('../assets/ads/tz_zemlerob_sponsor.gif', import.meta.url),
    imgMobile: new URL('../assets/ads/tz_zemlerob_sponsor.gif', import.meta.url),
    height: '115px',
    heightMobile: '115px',
    url: 'https://zemlerob.com.ua/',
    time: '00:00 - 00:00',
  },
  {
    // disable: true,
    type: 'report',
    time: '09:00',
    desc: 'Тема уточнюється',
    speakerName: 'Олександра Воловик',
    speakerDesc: 'Business Development Manager',
    speakerImg: new URL('../assets/speakers/volovik.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/volovik_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'break',
    time: '13:00',
    title: 'ОБІД',
  },
  {
    type: 'break',
    time: '17:30',
    title: 'ВЕЧІРНЯ ПРОГРАМА',
  },
];
